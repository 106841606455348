<template>
  <div class="resumeForm">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <draggable v-model="experiences" handle=".handle">
        <b-card v-for="(job, i) in experiences" :key="i" class="mt-3">
          <b-card-header class="clearfix" header-text-variant="primary">
            <b-icon icon="arrows-expand" class="handle mr-2"></b-icon>
            {{ i + 1 }} : {{ job.company_name }}
            <!-- Remove button -->
            <b-button
              v-on:click="removeExperience(i)"
              size="sm float-right"
              variant="outline-danger"
              class="ml-3"
              v-b-tooltip.hover
              title="Delete"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-button-group
              size="sm float-right"
              calss="mr-3"
              v-if="experiences.length > 1"
            >
              <!-- Move up -->
              <b-button
                v-on:click="MoveUp(i)"
                v-if="i != 0"
                size="sm float-right"
                variant="outline-dark"
                v-b-tooltip.hover
                title="Move up"
              >
                <b-icon icon="chevron-up" aria-hidden="true"></b-icon>
              </b-button>
              <!-- Move down -->
              <b-button
                v-on:click="MoveDn(i)"
                v-if="i != experiences.length - 1"
                size="sm float-right"
                variant="outline-dark"
                calss="ml-1"
                v-b-tooltip.hover
                title="Move down"
              >
                <b-icon icon="chevron-down" aria-hidden="true"></b-icon>
              </b-button>
            </b-button-group>
          </b-card-header>
          <!-- Cards inner -->
          <div class="card-inner">
            <b-form-group
              id="input-group2"
              label="Company Name:"
              label-for="'input2"
            >
              <b-form-input
                id="input2"
                v-model="job.company_name"
                placeholder="Company name"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group3" label="Role:" label-for="input3">
              <b-form-input
                id="input3"
                v-model="job.role"
                placeholder="Role summary at this position"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col>
                  Start Date:
                  <vue-monthly-picker
                    id="start-date"
                    v-model="job.start_date"
                    :monthLabels="locale"
                    alignment="left"
                    placeHolder="YYYY/MM"
                    dateFormat="YYYY/MM"
                  >
                  </vue-monthly-picker>
                </b-col>
                <b-col>
                  End Date:
                  <div :v-if="job.currently_here != 1">
                    <vue-monthly-picker
                      id="end-date"
                      v-model="job.end_date"
                      :monthLabels="locale"
                      placeHolder="YYYY/MM"
                      dateFormat="YYYY/MM"
                      :disabled="job.currently_here == 1"
                    >
                    </vue-monthly-picker>
                  </div>
                  <b-form-checkbox
                    :id="'checkbox-' + i"
                    v-model="job.currently_here"
                    name="checkbox-1"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Curretnly working here
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              :id="'input-group4-' + i"
              label="Company location:"
              :label-for="'input4-' + i"
            >
              <b-form-input
                :id="'input4-' + i"
                v-model="job.city"
                placeholder="City, State"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :id="'input-group2-' + i"
              label="Tasks list"
              :label-for="'input-text2-' + i"
            >
              <b-form-textarea
                rows="3"
                max-rows="10"
                :id="'input-text2-' + i"
                :ref="'ta'"
                v-model="tasks[i]"
                @focus="tasks[i] = tasks[i]"
                v-on:keydown="triggerKey($event, i)"
                placeholder="Enter task list. Hit enter for a new task"
              >
              </b-form-textarea>
            </b-form-group>
          </div>
        </b-card>
      </draggable>
      <div class="form-group">
        <b-button v-on:click="addExperience" variant="secondary" class="mt-2">
          <b-icon icon="plus-square" aria-hidden="true"></b-icon>
          Add experience
        </b-button>
      </div>
      <hr />
      <b-form-group class="text-center" id="buttons">
        <b-button
          center
          v-on:click="submit_form(false, $event)"
          id="bt_save"
          variant="outline-primary"
          class=""
          >Save</b-button
        >
        <b-button
          v-on:click="submit_form(true, $event)"
          id="bt_submit"
          variant="success"
          class="ml-5"
          >Next: Educations &#10132;</b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import VueMonthlyPicker from "vue-monthly-picker";
import draggable from "vuedraggable";
const bulletType = "•";
const bullet = bulletType + "\t";
export default {
  name: "FromExpernieces",
  components: {
    VueMonthlyPicker,
    draggable,
  },
  data() {
    return {
      locale: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      value: "",
      formatted: "",
      selected: "",
      tasks: [],
      show: true,
      experience_template: {
        id: 0,
        resume_id: this.$store.state.resume.id,
        company_name: "",
        role: "",
        title: "",
        tasks_list: '[""]',
        start_date: "",
        end_date: "",
        city: "",
        currently_here: 0,
      },
    };
  },
  computed: {
    experiences: {
      get() {
        return this.$store.state.resume.experiences;
      },
      set(val) {
        console.log("set experiences");
        let resume = this.$store.state.resume;
        resume.experiences = val;
        for (let i = 0; i < resume.experiences.length; i++) {
          resume.experiences[i].sort = i;
        }
        this.$store.commit("updateResume", resume);
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },
    },
  },
  //props: ["experiences"],

  watch: {
    experiences: function () {
      if (this.experiences.length == 0) this.addExperience();
      this.process_tasks_lists();
    },
    tasks: function () {},
  },
  created() {
    console.log(this.experiences);
    this.refresh_form();
    if (this.experiences.length == 0) this.addExperience();
    this.process_tasks_lists();
  },
  /**********************************************************/
  /**********************************************************/
  /**********************************************************/
  methods: {
    refresh_form() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    /**********************************************************/
    convert_dates() {
      for (let i = 0; i < this.experiences.length; i++) {
        if (this.experiences[i].start_date) {
          this.experiences[i].start_date = this.$options.filters.dateTime(
            this.experiences[i].start_date
          );
        }
        if (this.experiences[i].end_date) {
          this.experiences[i].end_date = this.$options.filters.dateTime(
            this.experiences[i].end_date
          );
        }
      }
    },
    /**********************************************************/
    convert_text_tasks_to_JSON() {
      let list = [];
      for (let i = 0; i < this.experiences.length; i++) {
        if (this.tasks[i].replaceAll(bullet, "") === this.tasks[i]) {
          this.tasks[i] = bullet + this.tasks[i];
        }
        list = this.tasks[i].replaceAll(bullet, "").split("\n");
        const result = list.filter((e) => e);
        this.experiences[i].tasks_list = JSON.stringify(result);
      }
      this.convert_dates();
    },
    /**********************************************************/
    process_tasks_lists() {
      for (let i = 0; i < this.experiences.length; i++) {
        if (this.experiences[i].tasks_list === "") {
          this.experiences[i].tasks_list = "[]";
        }
        this.tasks[i] = this.list2bullet(
          JSON.parse(this.experiences[i].tasks_list)
        );
      }
    },
    /**********************************************************/
    list2bullet(list) {
      let ret = "";

      for (let i = 0; i < list.length; i++) {
        ret += bullet + list[i];
        if (i != list.length - 1) ret += "\n";
      }

      return ret;
    },
    /**********************************************************/
    triggerKey(event, i) {
      if (event.key == "Enter") {
        const el = document.getElementById("input-text2-" + i);

        // Insert text into current position

        let cursorPos = el.selectionEnd;
        this.tasks[i] =
          this.tasks[i].substring(0, cursorPos) +
          "\n" +
          bullet +
          this.tasks[i].substring(cursorPos);
        cursorPos += bullet.length + 1;
        this.show = false;
        this.$nextTick(() => {
          this.show = true;

          setTimeout(() => {
            const el = document.getElementById("input-text2-" + i);
            el.focus(), 300;
            el.setSelectionRange(cursorPos, cursorPos);
          });
        });
      } else if (event.key == "Delete" || event.key == "Backspace") {
        if (
          this.tasks[i].substring(0, bullet.length) !== bullet ||
          this.tasks[i].length == 0
        ) {
          if (this.tasks[i][0] === bulletType) {
            this.tasks[i] = this.tasks[i].substring(1);
          }
          this.tasks[i] = bullet + this.tasks[i];

          this.refresh_form();
        }
      }
      this.convert_text_tasks_to_JSON();
    },
    /**********************************************************/
    MoveUp(i) {
      var b = this.experiences[i];
      this.experiences[i] = this.experiences[i - 1];
      this.experiences[i - 1] = b;
      this.refresh_form();
    },
    /**********************************************************/
    MoveDn(i) {
      var b = this.experiences[i];
      this.experiences[i] = this.experiences[i + 1];
      this.experiences[i + 1] = b;
      this.refresh_form();
    },
    /**********************************************************/
    onSubmit(event) {
      event.preventDefault();
    },
    /**********************************************************/
    onReset(event) {
      event.preventDefault();
      this.refresh_form();
    },
    /**********************************************************/
    submit_form(redirect, event) {
      event.preventDefault();
      this.convert_text_tasks_to_JSON();
      this.$emit("save", this.experiences, redirect);
    },
    /**********************************************************/
    addExperience() {
      // make a copy of experience objetc
      let newExperince = {
        ...this.experience_template,
      };
      this.experiences.push(newExperince);
      this.tasks.push(bullet);
    },
    /**********************************************************/
    removeExperience(i) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.experiences.splice(i, 1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.handle {
  cursor: move;
}
.card-inner {
  padding: 20px;
}
.card-body {
  padding: 0px;
}
.resumeForm {
  text-align: left;
}
</style>
