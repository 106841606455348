<template lang="">
  <div>
    <h3>Experinces</h3>
    <form-experinces
      :experiences="this.$store.state.resume.experiences"
      @save="save"
    />
  </div>
</template>
<script>
import FromExpernieces from "../components/forms/FormExperiences.vue";
export default {
  data() {
    return {
      experience_template: {
        id: 0,
        resume_id: this.$store.state.resume.id,
        company_name: "my company",
        role: "",
        title: "",
        tasks_list: '[""]',
        start_date: "",
        end_date: "",
        city: "",
        currently_here: "",
      },
    };
  },
  components: {
    "form-experinces": FromExpernieces,
  },
  mounted() {
    this.$api.fetchResumeExperinces();
  },
  methods: {
    save(experiences, redirect) {
      let resume = this.$store.state.resume;
      resume.experiences = experiences;
      this.$store.commit("updateResume", resume);
      this.$api.updateResumeExperiences(experiences);
      this.$api.successToast();

      if (redirect) {
        this.$router.push("educations");
      }
    },
  },
};
</script>
<style lang=""></style>
